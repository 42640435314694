import get from 'lodash.get';
import * as utils from '@utils';

import iconYes from '@assets/svg/table-icon-yes.svg';
import iconNo from '@assets/svg/table-icon-no.svg';
import { globalTranslations } from '@helpers/globalTranslations';
import { ICardWithColapseTableSection } from '@components/sections/CardWithColapseTable/CardWithColapseTable.d';

export const cardWithColapseTable = (nodeData, fieldName: string) => {
  const data = get(nodeData, `${fieldName}.entity`, undefined);
  const sRows = get(data, 'fieldSoftwareCompatibility.entity.fieldSystemCompatibilityTable.entity.fieldCardWithCollapseTable.value', []);
  const sDictionaryYes = get(data, 'fieldSoftwareCompatibility.entity.fieldSystemCompatibilityTable.entity.fieldDictionaryYes', 'Yes').toLowerCase();
  const sDictionaryNo = get(data, 'fieldSoftwareCompatibility.entity.fieldSystemCompatibilityTable.entity.fieldDictionaryNo', 'No').toLowerCase();

  const recommendedBadgeType = {
    ekonomy: {
      type: 1,
      title: globalTranslations('fieldRecommendeEkonomy')
    },
    advanced: {
      type: 2,
      title: globalTranslations('fieldRecommendedAdvanced')
    },
    premium: {
      type: 3,
      title: globalTranslations('fieldRecommendedPremium')
    },
  }

  const componentData: ICardWithColapseTableSection = {
    type: get(data, 'entityBundle', ''),
    headline: get(data, 'fieldHeadline.processed', ''),
    background: get(data, 'fieldBackground[0]', 'no') === 'yes',
    cardWithTable: [
      {
        anchorId: get(data, 'fieldCameraCompatibility.entity.fieldAnchorId'),
        headline: get(data, 'fieldCameraCompatibility.entity.fieldHeadline.processed', ''),
        description: get(data, 'fieldCameraCompatibility.entity.fieldCopy.processed', ''),
        colapseLink: get(data, 'fieldCameraCompatibility.entity.fieldLinkToTable', ''),
        image: get(data, 'fieldCameraCompatibility.entity.fieldImage', undefined) ? {
          url: get(data, 'fieldCameraCompatibility.entity.fieldImage.url', ''),
          alt: get(data, 'fieldCameraCompatibility.entity.fieldImage.alt', ''),
          title: get(data, 'fieldCameraCompatibility.entity.fieldImage.title', ''),
          webp: get(data, 'fieldCameraCompatibility.entity.fieldImage.derivative.url', ''),
        } : undefined,
        subimages: get(data, 'fieldCameraCompatibility.entity.fieldLogos', []).map(logo => ({
          url: get(logo, 'url', ''),
          alt: get(logo, 'alt', ''),
          title: get(logo, 'title', ''),
          webp: get(logo, 'derivative.url', ''),
        })),
        tabs: get(data, 'fieldCameraCompatibility.entity.fieldCameraCompatibilityTable', []).map(tab => {
          const rows = get(tab, 'entity.fieldCardWithCollapseTable.value', []);
          const dictionaryYes = get(tab, 'entity.fieldDictionaryYes', 'Yes').toLowerCase();
          const dictionaryNo = get(tab, 'entity.fieldDictionaryNo', 'No').toLowerCase();

          return {
            title: get(tab, 'entity.fieldTitle' , ''),
            table: {
              columnTitles: get(rows, '["0"]', []),
              tableRows: Object.keys(rows).filter(row => row !== "0" && row !== 'caption').map((row, rowIndex) => {
                const arrRow = rows[row];

                return arrRow.map((cell, cellIndex) => ({
                  copy: cell,
                  bold: cellIndex === 0,
                  icon: utils.dictionaryMatch(cell, dictionaryYes) || utils.dictionaryMatch(cell, dictionaryNo) ? {
                    url: utils.dictionaryMatch(cell, dictionaryYes) ? iconYes : iconNo,
                    type: utils.dictionaryMatch(cell, dictionaryYes) ? 'yes' : 'no',
                  } : undefined
                }))
              }),
              notes: get(tab, 'entity.fieldNotes.processed', ''),
            },
            recommended: {
              headline: get(tab, 'entity.fieldHeadline.processed', ''),
              description: get(tab, 'entity.fieldCopy.processed', ''),
              cards: get(tab, 'entity.fieldCameraRecommended', []).map(item => ({
                badgeTitle: recommendedBadgeType[get(item, 'entity.fieldSelectCamera', 'ekonomy')]?.title || '',
                badgeType: recommendedBadgeType[get(item, 'entity.fieldSelectCamera', 'ekonomy')]?.type || '',
                image: {
                  url: get(item, 'entity.fieldImage.url', ''),
                  alt: get(item, 'entity.fieldImage.alt', ''),
                  title: get(item, 'entity.fieldImage.title', ''),
                  webp: get(item, 'entity.fieldImage.derivative.url', ''),
                },
                description: get(item, 'entity.fieldCopy.processed', ''),
                cta: get(item, 'entity.fieldLinkNotCta', undefined) ? {
                  label: globalTranslations('fieldContactToBuy'),
                  url: get(item, 'entity.fieldLinkNotCta.url.path', '/')
                } : undefined,
              }))
            }
          }
        })
      },
      {
        anchorId: get(data, 'fieldSoftwareCompatibility.entity.fieldAnchorId'),
        headline: get(data, 'fieldSoftwareCompatibility.entity.fieldHeadline.processed', ''),
        description: get(data, 'fieldSoftwareCompatibility.entity.fieldCopy.processed', ''),
        colapseLink: get(data, 'fieldSoftwareCompatibility.entity.fieldLinkToTable', ''),
        image: get(data, 'fieldSoftwareCompatibility.entity.fieldImage', undefined) ? {
          url: get(data, 'fieldSoftwareCompatibility.entity.fieldImage.url', ''),
          alt: get(data, 'fieldSoftwareCompatibility.entity.fieldImage.alt', ''),
          title: get(data, 'fieldSoftwareCompatibility.entity.fieldImage.title', ''),
          webp: get(data, 'fieldSoftwareCompatibility.entity.fieldImage.derivative.url', ''),
        } : undefined,
        subimages: get(data, 'fieldSoftwareCompatibility.entity.fieldLogos', []).map(logo => ({
          url: get(logo, 'url', ''),
          alt: get(logo, 'alt', ''),
          title: get(logo, 'title', ''),
          webp: get(logo, 'derivative.url', ''),
        })),
        tabs: [{
          table: {
            columnTitles: get(sRows, '["0"]', []),
            tableRows: Object.keys(sRows).filter(row => row !== "0" && row !== 'caption').map((row, rowIndex) => {
              const arrRow = sRows[row];

              return arrRow.map((cell, cellIndex) => ({
                copy: cell,
                bold: cellIndex === 0,
                icon: utils.dictionaryMatch(cell, sDictionaryYes) || utils.dictionaryMatch(cell, sDictionaryNo) ? {
                  url: utils.dictionaryMatch(cell, sDictionaryYes) ? iconYes : iconNo,
                  type: utils.dictionaryMatch(cell, sDictionaryYes) ? 'yes' : 'no',
                } : undefined
              }))
            }),
            notes: get(data, 'fieldSoftwareCompatibility.entity.fieldSystemCompatibilityTable.entity.fieldNotes.processed', ''),
          },
        }]
      },
    ],
    cardTextImage: get(data, 'fieldTextAndImage', []).map((card) => ({
      headline: get(card, 'entity.fieldHeadline.processed', ''),
      description: get(card, 'entity.fieldDescription.processed', ''),
      image: get(card, 'entity.fieldImage', undefined)
        ? {
            alt: get(card, 'entity.fieldImage.alt', ''),
            title: get(card, 'entity.fieldImage.title', ''),
            url: get(card, 'entity.fieldImage.url', ''),
            webp: get(card, 'entity.fieldImage.derivative.url'),
          }
        : undefined,
      video: get(card, 'entity.fieldVideo.entity.url', undefined),
      videoUrl: get(card, 'entity.fieldVideoUrl.url.path', undefined),
      label: get(card, 'entity.fieldLabel', undefined)
        ? {
            title: get(card, 'entity.fieldLabel', ''),
            url: get(card, 'entity.fieldLabelLink.url.path', ''),
            color: get(card, 'entity.fieldLabelColor', null),
          }
        : undefined,
      cta: get(card, 'entity.fieldLinkCta', undefined)
        ? {
            label: get(card, 'entity.fieldLinkCta.title', ''),
            url: get(card, 'entity.fieldLinkCta.url.path', ''),
          }
        : undefined,
      imageOrVideo: get(card, 'entity.fieldSelectImageOrVideo', 'image'), // return: 'image' | 'video'
      poster: get(card, 'entity.fieldPoster', undefined) ? {
        alt: get(card, 'entity.fieldPoster.alt', ''),
        title: get(card, 'entity.fieldPoster.title', ''),
        url: get(card, 'entity.fieldPoster.url', ''),
        webp: get(card, 'entity.fieldPoster.derivative.url'),
      } : undefined,
      schemaData: get(card, 'entity.fieldName', undefined) ? {
        name: get(card, 'entity.fieldName', ''),
        duration: get(card, 'entity.fieldVideoDuration', ''),
        description: get(card, 'entity.fieldVideoDescription', ''),
        uploadDate: get(card, 'entity.entityCreated', '')
      } : undefined,
      slider: get(card, 'entity.fieldSliderParagraph', []).map((slide) => ({
        image: {
          alt: get(slide, 'entity.fieldSlider.alt', ''),
          title: get(slide, 'entity.fieldSlider.title', ''),
          url: get(slide, 'entity.fieldSlider.url', ''),
          webp: get(slide, 'entity.fieldSlider.derivative.url'),
        },
        video: get(slide, 'entity.fieldVideo.entity.url') ? {
          description: get(slide, 'entity.fieldVideo.description', ''),
          url: get(slide, 'entity.fieldVideo.entity.url', ''),
          schemaData: get(slide, 'entity.fieldName', undefined) ? {
            name: get(slide, 'entity.fieldName', ''),
            duration: get(slide, 'entity.fieldVideoDuration', ''),
        description: get(slide, 'entity.fieldVideoDescription', ''),
            uploadDate: get(slide, 'entity.entityCreated', '')
          } : undefined,
        } : undefined,
      })),
    })),
    customerLogos: get(data, 'fieldLogos', []).map((logo) => ({
      url: get(logo, 'url', ''),
      alt: get(logo, 'alt', ''),
      title: get(logo, 'title', ''),
      webp: get(logo, 'derivative.url', ''),
    })),
  };

  return componentData;
}