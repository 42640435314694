import React from 'react';

// === Components === //
import Helmet from 'react-helmet';
import Layout from '@components/layout';
import PageComponents from '@components/layout/PageComponents/PageComponents';

// === Content === //
import { SupportPageDTO } from '@DTO/SupportPageDTO';

// === Helpers === //
import { breadcrumbsObject, organizationObject } from '@helpers/schemaOrg';

// === Types === //
import { TemplatePageContextData } from '@type/index.d';

interface SupportPageProps {
  pageContext?: TemplatePageContextData;
}

const SupportPage = ({ pageContext }: SupportPageProps) => {
  const pageData = SupportPageDTO(pageContext?.uuid);

  return (
    <>
      <Helmet>
        <script type="application/ld+json">
          {JSON.stringify(breadcrumbsObject(pageData.breadcrumbs.list))}
        </script>
        <script type="application/ld+json">{JSON.stringify(organizationObject())}</script>
      </Helmet>
      <Layout
        metaData={pageData.metaData}
        breadcrumbs={pageData.breadcrumbs}
        stickedForm={pageData.stickedForm}
        bannerContactInfo={undefined}
        navLangPath={pageData.langPath}
      >
        <PageComponents components={pageData.components} slug={`/${pageContext?.slug || ''}`} />
      </Layout>
    </>
  );
};

export default SupportPage;
