import { replaceLangCodes } from '@utils';
import { graphql, useStaticQuery } from 'gatsby';

export const supportPageData = () => {
  const data = useStaticQuery(graphql`
    query supportPageQuery {
      drupalData {
        nodeQuery(filter: { conditions: { field: "type", value: "support_page" } }, limit: 1000) {
          entities {
            ... on drupalData_NodeSupportPage {
              entityLabel

              fieldMetatags {
                entity {
                  ...ParagraphMetatags
                }
              }
              fieldMainBanner {
                entity {
                  ...ParagraphMainBannerVariant1
                  ...ParagraphMainBannerVariant2
                  ...ParagraphMainBannerVariant3
                }
              }
              fieldCardTextImage {
                entity {
                  ...ParagraphCardTextImage
                }
              }
              fieldCardWithCollapseTable {
                entity {
                  ...ParagraphCardWithCollapseTable
                }
              }
              fieldFaq {
                entity {
                  ...ParagraphFaq
                }
              }
              fieldSupportContactForm {
                entity {
                  ...ParagraphSupportContactForm
                }
              }
              fieldFormOffer
              fieldForm {
                entity {
                  uuid
                }
              }

              path {
                alias
              }
              title
              uuid
              langcode {
                value
              }
              sticky: entityMultisiteEnabled
              status
              entityMultisiteTranslations {
                entity {
                  entityTranslations {
                    entityLabel
                    entityLanguage {
                      id
                    }
                    ... on drupalData_Node {
                      status
                      path {
                        alias
                      }
                    }
                  }
                }
              }
              entityTranslations {
                entityLabel
                entityLanguage {
                  id
                }
                ... on drupalData_Node {
                  status
                  path {
                    alias
                  }
                }
              }
            }
          }
        }
      }
    }
  `);

  return replaceLangCodes(data);
};
