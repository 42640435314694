import get from 'lodash.get';

import { form } from '@DTO/sections/form/Form';
import { ISupportContactForm } from '@components/sections/SupportContactForm/SupportContactForm.d';

export const supportContactForm = (nodeData, fieldName: string, previewData?: unknown) => {
  const data = get(nodeData, `${fieldName}.entity`, undefined);
  const formId = get(data, 'fieldForm.entity.uuid', '');

  const mappedData: ISupportContactForm = {
    type: get(data, 'entityBundle', ''),
    headline: get(data, 'fieldHeadline.processed', ''),
    description: get(data, 'fieldCopy.processed', ''),
    background: get(data, 'fieldBackground[0]', 'no') === 'yes',
    form: form(formId, previewData ? {
      drupalData: {
        nodeQuery: get(previewData, 'form', undefined)
      }
    } : undefined),
  };

  return mappedData;
};
